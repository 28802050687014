.animatedbutton {
  animation: mymove 6s ease-in-out infinite;
}

@keyframes mymove {
  0% {
    background-color: #007a95;
    outline: none;
  }
  25% {
    background-color: #92106d;
    outline: 4px solid #92106d;
    outline-offset: 5px;
  }
  50% {
    background-color: #585858;
    outline: 3px solid #585858;
    outline-offset: 5px;
  }
  75% {
    background-color: #170f2e;
    outline: 2px solid #170f2e;
    outline-offset: 5px;
  }
  100% {
    background-color:   #171717;
    outline: 1px solid #171717;
    outline-offset: 5px;
  }
}
